
import { Fragment } from './Fragment';
import { loadScript } from '@core/utils/helpers';
import srcConfig from '@core/config/src.config';
import { State as ArticleStoreState } from '@root/modules/article/types/article';
const fragment = new Fragment();
import { CoreWindow } from '@core/types/window';
fragment.setRequiredAttrs(['code']);

const FragmentComponent = fragment.createComponent();

type ParsedAttributes = {
  liveblogId: string | null;
  adsFrequency: number | null;
  adsKeywords: string | null;
  adsServer: string | null;
};

export default FragmentComponent.extend({
  name: 'FragmentTickaroo',
  async mounted() {
    const { code } = this;

    if (!window.customElements.get('tickaroo-liveblog')) {
      try {
        await loadScript({ script: srcConfig['tickaroo'], attr: { async: true }, parentElement: this.$refs.tickarooElement as HTMLElement });
      } catch (e) {
        console.error('FragmentTickaroo loadScript error', e);
      }
    }

    this.createLiveblog(code);
  },
  computed: {
    activeArticleData(): ArticleStoreState['activeArticle'] {
      return this.$store.getters['article/getActiveArticle'];
    },
  },
  methods: {
    extractAttributes(code: string): ParsedAttributes {
      // eg. 6762ca52da0e753b0dd6740c | adsKeywords="basketball;rally;kroonika" | adsServer="GAM" | adsFrequency="5"
      if (!code || typeof code !== 'string' || code.trim() === '') {
        return {
          liveblogId: null,
          adsFrequency: null,
          adsKeywords: null,
          adsServer: null,
        };
      }

      const parts = code.split('|').map((p) => p.trim());

      const result: ParsedAttributes = {
        liveblogId: parts[0] || null,
        adsFrequency: null,
        adsKeywords: null,
        adsServer: null,
      };

      parts.slice(1).forEach((part) => {
        const [key, value] = part.split('=');
        if (key && value) {
          const cleanKey = key.trim();
          const cleanValue = value.trim().replace(/^"|"$/g, '');
          if (cleanKey === 'adsFrequency') {
            result.adsFrequency = parseInt(cleanValue, 10);
          } else if (cleanKey === 'adsKeywords') {
            result.adsKeywords = cleanValue;
          } else if (cleanKey === 'adsServer') {
            result.adsServer = cleanValue;
          }
        }
      });

      return result;
    },
    collectKeywords(keywords: string | null, article: ArticleStoreState['activeArticle']) {
      const keywordsArray = [];

      if (!article) {
        return '';
      }

      keywordsArray.push(`channel_${article.primaryCategory.channel.name}`);
      keywordsArray.push(`category_${article.primaryCategory.id}`);
      keywordsArray.push(`article_${article.id}`);

      const safeKeywords = keywords?.endsWith(';') ? keywords : keywords + ';';
      return keywords ? safeKeywords?.concat(keywordsArray.join(';')) : keywordsArray.join(';');
    },
    createLiveblog(code: string | undefined) {
      if (!code) {
        return;
      }

      const article = this.activeArticleData;
      const attributes = this.extractAttributes(code);

      if (!attributes.liveblogId) {
        return;
      }

      const parentElement = this.$refs.tickarooElement as HTMLElement;
      const liveblogConf = document.createElement('div');

      liveblogConf.setAttribute('data-liveblogid', attributes.liveblogId || '');
      liveblogConf.setAttribute('data-adsfrequency', article?.banners.disableAds ? '0' : attributes.adsFrequency?.toString() || '5');
      liveblogConf.setAttribute('data-adsserver', attributes.adsServer || '');
      liveblogConf.setAttribute('data-adskeywords', this.collectKeywords(attributes.adsKeywords, article));
      liveblogConf.setAttribute('data-language', article?.primaryCategory.channel.language || 'ET');
      liveblogConf.setAttribute('data-channel', article?.primaryCategory.channel.name || '');
      liveblogConf.setAttribute('data-categoryid', article?.primaryCategory.id.toString() || '');

      parentElement.appendChild(liveblogConf);

      this.$nextTick(() => {
        const win = window as unknown as CoreWindow;
        win.tickaroo_blogs = win.tickaroo_blogs || [];
        win.tickaroo_blogs.push(liveblogConf);
      });
    },
  },
});
