import FragmentHtml from '@core/components/fragments/FragmentHtml.vue';
import FragmentPicture from '@core/components/fragments/FragmentPicture.vue';
import FragmentIframe from '@core/components/fragments/FragmentIframe.vue';
import FragmentSidebar from '@core/components/fragments/FragmentSidebar.vue';
import FragmentPullout from '@core/components/fragments/FragmentPullout.vue';
import FragmentQuiz from '@core/components/fragments/FragmentQuiz.vue';
import FragmentJsmk from '@core/components/fragments/FragmentJsmk.vue';
import FragmentEmbed from '@core/components/fragments/FragmentEmbed.vue';
import FragmentDstream from '@core/components/fragments/FragmentDstream.vue';
import FragmentRecipe from '@core/components/fragments/FragmentRecipe.vue';
import FragmentEmbeddedArticles from '@core/components/fragments/FragmentEmbeddedArticles.vue';
import FragmentTickaroo from '@core/components/fragments/FragmentTickaroo.vue';

export default {
  FragmentHtml,
  FragmentPicture,
  FragmentIframe,
  FragmentSidebar,
  FragmentPullout,
  FragmentQuiz,
  FragmentJsmk,
  FragmentEmbed,
  FragmentDstream,
  FragmentRecipe,
  FragmentEmbeddedArticles,
  FragmentTickaroo,
};
