export default {
  twitter: 'https://platform.twitter.com/widgets.js',
  instagram: '//www.instagram.com/embed.js',
  imgur: '//s.imgur.com/min/embed.js',
  playbuzz: 'https://embed.ex.co/sdk.js',
  quiz: '//g.delfi.ee/m/vuepoll/loader.js',
  liveblog: 'https://lb.delfi.ee/js/liveupdater.js',
  rembi: '//g.delfi.ee/misc/rembi/embed.php',
  recipe_web: 'https://retseptiveeb.delfi.ee/_nuxt/embeds/embed.js',
  tickaroo: 'https://static.delfi.ee/portal-js-cdn/prod/tickaroo.js',
} as Record<string, string>;
